import request from 'lib/request';
import swal from 'sweetalert2';
import { showErrorWithCode } from 'components/common';
export const CURRENT_USER_REQUEST = 'CURRENT_USER_REQUEST';
export const CURRENT_USER_REQUEST_ERROR = 'CURRENT_USER_REQUEST_ERROR';
export const CURRENT_USER_REQUEST_SUCCESS = 'CURRENT_USER_REQUEST_SUCCESS';
export const CURRENT_USER_FETCHING = 'CURRENT_USER_FETCHING';
export const IS_GET_API = 'IS_GET_API';
export const GET_LEVEL_INFO_SUCCESS = 'GET_LEVEL_INFO_SUCCESS';
export const OPEN_CARD_SUCCESS = 'OPEN_CARD_SUCCESS';
export const UPDATE_INFO_SUCCESS = 'UPDATE_INFO_SUCCESS';
export const CHECK_PROGRESS_SUCCESS = 'CHECK_PROGRESS_SUCCESS';
export const SHARE_FB_SUCCESS = 'SHARE_FB_SUCCESS';
export const SHARE_FACEBOOK = 'facebook.share';
export const GET_HISTORY_SUCCESS = 'GET_HISTORY_SUCCESS';
export const CLAIM_MEDAL_SUCCESS = 'CLAIM_MEDAL_SUCCESS';
export const CLAIM_MISSION_SUCCESS = 'CLAIM_MISSION_SUCCESS';
export const CHECK_MISSION_SUCCESS = 'CHECK_MISSION_SUCCESS';
export const VOTE_TEAM_SUCCESS = 'VOTE_TEAM_SUCCESS';
export const CHANGE_MAP_SUCCESS = 'CHANGE_MAP_SUCCESS';

export const isGetApi = () => {
	return {
		type: IS_GET_API,
	};
};
//user
export const isCurrentUserFetching = () => {
	return {
		type: CURRENT_USER_FETCHING,
	};
};
export const getCurrentUser = () => {
	return (dispatch, getState) => {
		dispatch(isCurrentUserFetching());
		request('api/user/get').then(function (response) {
			if (response.status == 'successful') {
				dispatch(getCurrentUserSuccess(response));
				if (!response.payload.user.account_id) {
					swal({
						title: 'Thông báo',
						html: '<p class="pop-content">Bạn chưa tạo HLV, vui lòng tạo HLV rồi quay lại.</p>',
						animation: false,
						confirmButtonText: 'Đóng',
						customClass: 'custom-modal animated zoomIn',
					}).then((result) => {
						window.location.href = '/user/logout';
					});
				}
			} else {
				dispatch(getCurrentUserError(response));
				if (response.error_code == 'error_account_not_found') {
					swal({
						title: 'Thông báo',
						html: '<p class="pop-content">Bạn chưa tạo HLV, vui lòng tạo HLV rồi quay lại.</p>',
						animation: false,
						confirmButtonText: 'Đóng',
						customClass: 'custom-modal animated zoomIn',
					}).then((result) => {
						window.location.href = '/user/logout';
					});
				}
			}
		});
	};
};
export const getCurrentUserSuccess = (response) => {
	return {
		type: CURRENT_USER_REQUEST_SUCCESS,
		payload: response.payload,
	};
};
export const getCurrentUserError = (response) => {
	return {
		type: CURRENT_USER_REQUEST_ERROR,
		payload: response.payload,
	};
};

//update info
export const UpdateInfo = (name, address, phone_number, callback) => {
	return (dispatch, getState) => {
		dispatch(isGetApi());
		request('api/user/register', 'POST', {
			body: JSON.stringify({
				name: name,
				address: address,
				phone_number: phone_number,
			}),
		}).then(function (response) {
			if (response.status == 'successful') {
				dispatch(UpdateInfoSuccess(response));
				swal({
					title: 'Thông báo',
					html: '<p class="pop-content">Cập nhập thông tin thành công</p>',
					confirmButtonText: 'Đóng',
					animation: false,
					customClass: 'custom-modal animated zoomIn',
				});
				callback();
			} else {
				swal({
					title: 'Thông báo',
					html:
						'<p class="pop-content">' +
						showErrorWithCode(response.error_code) +
						'</p>',
					animation: false,
					showCloseButton: true,
					confirmButtonText: 'Đóng',
					customClass: 'custom-modal animated zoomIn',
				});
			}
		});
	};
};
export const UpdateInfoSuccess = (response) => {
	return {
		type: UPDATE_INFO_SUCCESS,
		payload: response.payload,
	};
};
//check mission
export const checkProgress = () => {
	return async (dispatch, getState) => {
		dispatch(isGetApi());
		const response = await new Promise((resolve, reject) => {
			request('api/user/check-match', 'POST', {
				body: JSON.stringify({}),
			})
				.then(function (response) {
					resolve(response);
				})
				.catch(function (error) {
					reject(error);
				});
		});
		if (response.status == 'successful') {
			dispatch(checkProgressSuccess(response));
		} else {
			swal({
				title: 'Thông báo',
				html:
					'<p class="pop-content">' +
					showErrorWithCode(response.error_code) +
					'</p>',
				animation: false,
				confirmButtonText: 'Đóng',
				customClass: 'custom-modal animated zoomIn',
			});
		}
		return response;
	};
};
export const checkProgressSuccess = (response) => {
	return {
		type: CHECK_PROGRESS_SUCCESS,
		payload: response.payload,
	};
};
//share FB
export const shareFB = (url) => {
	let type = SHARE_FACEBOOK;
	return (dispatch) => {
		FB.ui(
			{
				method: 'share',
				href: url,
				hashtag: '##FCOcupvang',
				quote:
					'QUÀ MIỄN PHÍ LẠI CHẮC CHẮN NHẬN ĐƯỢC NỮA, CHƠI THÔI ANH EM ƠI !!!',
			},
			(response) => {
				// if (response && !response['error_code']) {
				request('api/user/share', 'POST', {
					body: JSON.stringify({}),
				}).then(function (response) {
					if (response.status == 'successful') {
						dispatch(shareFBSuccess(response));
						swal({
							title: 'Thông báo',
							html: '<p class="pop-content">Chia sẻ thành công. Bạn nhận được thêm thể lực.</p>',
							confirmButtonText: 'Đóng',
							animation: false,
							customClass: 'custom-modal animated zoomIn',
						});
					} else {
						swal({
							title: 'Thông báo',
							html:
								'<p class="pop-content">' +
								showErrorWithCode(response.error_code) +
								'</p>',
							confirmButtonText: 'Đóng',
							animation: false,
							customClass: 'custom-modal animated zoomIn',
						});
					}
				});
				// } else {
				// 	swal({
				// 		title: 'Thông báo',
				// 		html: '<p class="pop-content">Chia sẻ không thành công. Vui lòng thử lại</p>',
				// 		confirmButtonText: 'Đóng',
				// 		animation: false,
				// 		customClass: 'custom-modal animated zoomIn',
				// 	});
				// }
			}
		);
		dispatch(() => {
			type: SHARE_FACEBOOK;
		});
	};
};
export const shareFBSuccess = (response) => {
	return {
		type: SHARE_FB_SUCCESS,
		payload: response.payload,
	};
};

export const openCard = (direction, spinAnimate) => {
	return async (dispatch, getState) => {
		dispatch(isGetApi());
		const response = await request('api/user/move', 'POST', {
			body: JSON.stringify({
				direction: direction,
			}),
		});
		if (response.status == 'successful') {
			await spinAnimate(response.payload);
			dispatch(openCardSuccess(response));
			$('.buttonSpin').removeClass('prevent-click');
			$('.btn-spin-act').removeClass('prevent-click');
		} else {
			swal({
				title: 'Thông báo',
				html:
					'<p class="pop-content">' +
					showErrorWithCode(response.error_code) +
					'</p>',
				animation: false,
				confirmButtonText: 'Đóng',
				customClass: 'custom-modal animated zoomIn',
			});
			$('.buttonSpin').removeClass('prevent-click');
			$('.btn-spin-act').removeClass('prevent-click');
		}
	};
};
export const openCardSuccess = (response) => {
	return {
		type: OPEN_CARD_SUCCESS,
		payload: response.payload,
	};
};
//get level
export const getHistory = () => {
	return (dispatch, getState) => {
		dispatch(isGetApi());
		request('api/user/history').then(function (response) {
			if (response.status == 'successful') {
				dispatch(getHistorySuccess(response));
			} else {
			}
		});
	};
};
export const getHistorySuccess = (response) => {
	return {
		type: GET_HISTORY_SUCCESS,
		payload: response.payload,
	};
};
//claim point
export const calimMedal = (stage) => {
	return (dispatch, getState) => {
		dispatch(isGetApi());
		request('api/user/claim', 'POST', {
			body: JSON.stringify({
				stage: stage,
			}),
		}).then(function (response) {
			if (response.status == 'successful') {
				dispatch(calimMedalSuccess(response));
				swal({
					title: 'thông báo',
					html: '<p class="pop-content">Nhận quà thành công. Quà sẽ được trao trong 24h</p>',
					animation: false,
					confirmButtonText: 'Đóng',
					customClass: 'custom-modal animated zoomIn',
				});
			} else {
				swal({
					title: 'Thông báo',
					html:
						'<p class="pop-content">' +
						showErrorWithCode(response.error_code) +
						'</p>',
					animation: false,
					confirmButtonText: 'Đóng',
					customClass: 'custom-modal animated zoomIn',
				});
			}
		});
	};
};
export const calimMedalSuccess = (response) => {
	return {
		type: CLAIM_MEDAL_SUCCESS,
		payload: response.payload,
	};
};
//claim mission
export const claimMission = (mission_index) => {
	return (dispatch, getState) => {
		dispatch(isGetApi());
		request('api/user/claim-mission-by-day', 'POST', {
			body: JSON.stringify({
				mission_index: mission_index,
			}),
		}).then(function (response) {
			if (response.status == 'successful') {
				dispatch(claimMissionSuccess(response));
				swal({
					title: 'Thông báo',
					html: '<p class="pop-content">Nhận nhiệm vụ thành công.</p>',
					animation: false,
					confirmButtonText: 'Đóng',
					customClass: 'custom-modal animated zoomIn',
				});
			} else {
				if (response.error_code == 'invalid_data') {
					swal({
						title: 'Thông báo',
						html: '<p class="pop-content">Bạn có nhiệm vụ chưa hoàn thành.</p>',
						animation: false,
						confirmButtonText: 'Đóng',
						customClass: 'custom-modal animated zoomIn',
					});
				} else {
					swal({
						title: 'Thông báo',
						html:
							'<p class="pop-content">' +
							showErrorWithCode(response.error_code) +
							'</p>',
						animation: false,
						confirmButtonText: 'Đóng',
						customClass: 'custom-modal animated zoomIn',
					});
				}
			}
		});
	};
};
export const claimMissionSuccess = (response) => {
	return {
		type: CLAIM_MISSION_SUCCESS,
		payload: response.payload,
	};
};
export const checkMission = () => {
	return async (dispatch, getState) => {
		dispatch(isGetApi());
		const response = await new Promise((resolve, reject) => {
			request('api/user/check-login', 'POST', {
				body: JSON.stringify({}),
			})
				.then(function (response) {
					resolve(response);
				})
				.catch(function (error) {
					reject(error);
				});
		});
		if (response.status === 'successful') {
			dispatch(checkMissionSuccess(response));
		} else {
			swal({
				title: 'Thông báo',
				html:
					'<p class="pop-content">' +
					showErrorWithCode(response.error_code) +
					'</p>',
				animation: false,
				confirmButtonText: 'Đóng',
				customClass: 'custom-modal animated zoomIn',
			});
		}
	};
};
export const checkMissionSuccess = (response) => {
	return {
		type: CHECK_MISSION_SUCCESS,
		payload: response.payload,
	};
};
export const changeMap = () => {
	return (dispatch, getState) => {
		dispatch(isGetApi());
		request('api/user/change-map', 'POST', {
			body: JSON.stringify({}),
		}).then(function (response) {
			if (response.status == 'successful') {
				dispatch(changeMapSuccess(response));
			} else {
				swal({
					title: 'Thông báo',
					html:
						'<p class="pop-content">' +
						showErrorWithCode(response.error_code) +
						'</p>',
					animation: false,
					confirmButtonText: 'Đóng',
					customClass: 'custom-modal animated zoomIn',
				});
			}
		});
	};
};
export const changeMapSuccess = (response) => {
	return {
		type: CHANGE_MAP_SUCCESS,
		payload: response.payload,
	};
};
//vote win
export const voteTeam = (match_id, result, callBack) => {
	return (dispatch, getState) => {
		dispatch(isGetApi());
		request('api/user/vote', 'POST', {
			body: JSON.stringify({
				match_id: match_id,
				result: result,
			}),
		}).then(function (response) {
			if (response.status == 'successful') {
				dispatch(voteTeamSuccess(response));
				swal({
					title: 'thông báo',
					html: '<p class="pop-content">Dự đoán thành công</p>',
					animation: false,
					confirmButtonText: 'Đóng',
					customClass: 'custom-modal animated zoomIn',
				});
				callBack(response.payload.match);
			} else {
				swal({
					title: 'Thông báo',
					html:
						'<p class="pop-content">' +
						showErrorWithCode(response.error_code) +
						'</p>',
					animation: false,
					confirmButtonText: 'Đóng',
					customClass: 'custom-modal animated zoomIn',
				});
			}
		});
	};
};
export const voteTeamSuccess = (response) => {
	return {
		type: VOTE_TEAM_SUCCESS,
		payload: response.payload,
	};
};
