import React from "react";
import Modal from 'components/Modal'
import moment from 'moment'
import {NavLink} from "react-router-dom";
import swal from "sweetalert2"
import Scrollbar from 'react-scrollbars-custom';
import {smoothScroll, formatNumber, img, commingSoonFnc, isStartEvent, inGame} from 'components/common'

const menu = [
    {id: 1, text: 'dành cho tất cả người chơi',},
    {id: 2, text: 'DÀNH CHO NGƯỜI CHƠI MỚI & NGƯỜI CŨ TRỞ LẠI',},
]
export default class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: null,
            history_recepie: null,
            history: null,
            history_cake_sp: null,
            rewardHistory: null,
            history_shop: null,
            history_tab: 1,
            gift_tab: 1,
            event_started: 0,
            instruction_url: null,
        }
    }

    componentDidMount() {
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.parentProp.home.rewardHistory != null && !nextProps.parentProp.home.loading) {
            this.setState({
                rewardHistory: nextProps.parentProp.home.rewardHistory,
            })
        }
        if (nextProps.parentProp.home.event_started != null && !nextProps.parentProp.home.loading) {
            this.setState({
                event_started: nextProps.parentProp.home.event_started,
            })
        }
        if (nextProps.parentProp.home.history != null && !nextProps.parentProp.home.loading) {
            this.setState({
                history: nextProps.parentProp.home.history,
            })
        }
        if (this.props.parentProp.home.event_started != null) {
            this.setState({
                event_started: this.props.parentProp.home.event_started,
            })
        }
        if (this.props.parentProp.home.instruction_url != null) {
            this.setState({
                instruction_url: this.props.parentProp.home.instruction_url,
            })
        }
    }

    handleClosePopup() {
        this.setState({modal: null})
        $('body').removeClass('modal-open');
    }

    handleOpenPopup(modal) {
        this.setState({modal: modal})
        $('body').addClass('modal-open');
    }

    commingSoon() {
        if (this.state.event_started == 0) {
            commingSoonFnc()
            return
        }
        window.location.href = "/user/login"
    }

    reloadFC() {
        this.props.parentProp.reloadFC()
    }

    mapEvenStatus(status) {
        if (!status) {
            return
        }
        let eventStatus;
        switch (status) {
            case 1:
                eventStatus = 'Đang xử lý'
                break;
            case 2:
                eventStatus = 'Đã gửi'
                break;
            case 3:
                eventStatus = 'Đang xử lý'
                break;
            case 4:
                eventStatus = 'Đang xử lý'
                break;
            case 5:
                eventStatus = 'Đang xử lý'
                break;
            default:
                break;
        }
        return eventStatus
    }

    handleViewHistory() {
        // if (this.state.event_started != 1) {
        //   commingSoonFnc()
        //   return
        // }
        // if (!this.props.parentProp.home.login) {
        //   swal({
        //     title: 'Thông báo',
        //     html: '<p class="pop-content">Bạn cần đăng nhập để tham gia sự kiện.</p>',
        //     confirmButtonText: 'Đóng',
        //     animation: false,
        //     customClass: 'custom-modal animated zoomIn'
        //   }).then((result) => {
        //     window.location.href = "/user/login"
        //   })
        //   return
        // }
        this.props.parentProp.getHistory()
        this.handleOpenPopup('history')
    }

    mapEventName(status) {
        if (!status) {
            return
        }
        let eventStatus;
        switch (status) {
            // Giải hạng 3
            // Giải hạng 2
            // Giải hạng 1
            // Giải ngoại hạng
            // Siêu cúp
            case 'spin1':
                eventStatus = 'Giải hạng 3'
                break;
            case 'spin2':
                eventStatus = 'Giải hạng 2'
                break;
            case 'spin3':
                eventStatus = 'Giải hạng 1'
                break;
            case 'spin4':
                eventStatus = 'Giải ngoại hạng'
                break;
            case 'spin5':
                eventStatus = 'Siêu cúp'
                break;
            default:
                break;
        }
        return eventStatus
    }

    render() {
        let {layout} = this.props;
        const {modal, rewardHistory, gift_tab, history} = this.state
        return (
            <React.Fragment>
                <header>
                    <div className="wrap-content">
                        <ul className="menu">
                            <li className="logo">
                                {
                                    inGame != null && inGame != undefined && inGame != '' ? (
                                        <a href="javascript:void(0)"><img src="images/logo.png" alt=""/></a>
                                    ) : (
                                        <a href="https://fo4.garena.vn/"><img src="images/logo.png" alt=""/></a>
                                    )
                                }
                            </li>
                            <li>
                                <a href="javascript:void(0)" className="btn-spin-act"
                                   onClick={e => this.handleOpenPopup('pop-rule')}>Hướng dẫn</a>
                            </li>
                            <li>
                                <a href="javascript:void(0)" className="btn-spin-act"
                                   onClick={() => this.handleViewHistory()}>lịch sử</a>
                            </li>
                            <li>
                                <a href="javascript:void(0)" className="btn-spin-act"
                                   onClick={e => this.handleOpenPopup('pop-gift')}>quà có thể nhận</a>
                            </li>
                            {
                                this.props.currentUser.login &&
                                <React.Fragment>
                                    <li className="user">
                                        <span className="txt-user">
                                          <span>Hi </span> <b> <span
                                            className="text-neon"><span>{this.props.currentUser.currentUser.user.nickname}</span></span></b>
                                        </span>
                                    </li>
                                </React.Fragment>
                            }
                            <li className="action-l-group">
                                {!this.props.currentUser.login ? (
                                    <React.Fragment>
                                        <a className="action" href="javascript:void(0)"
                                           onClick={e => this.commingSoon()}><span>ĐĂNG NHẬP</span></a>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        {
                                            inGame != 1 &&
                                            <a className="action buttonSpin" href="/user/logout"><span>đăng xuất</span></a>
                                        }

                                    </React.Fragment>
                                )}
                            </li>
                        </ul>
                    </div>
                </header>
                <Modal show={modal === 'pop-gift'} customClass={'pop-gift pop-no-padd'}
                       closeHandler={() => this.handleClosePopup()}>
                    {/* <div className="pop-gift-view">
            <ul>
              {
                menu &&
                menu.map((item, index) => (
                  <li key={index}>
                    <a href="javascript:void(0)" className={`${item.id == gift_tab ? 'active' : ''}`} onClick={e => this.setState({ gift_tab: item.id })}>{item.text}</a>
                  </li>
                ))
              }
            </ul>
            {
              gift_tab == 1 &&
              <img src="https://cdn.vn.garenanow.com/web/fo4vn/Khoa/Jan2021/DB7/Popup_DB7_Qua1.png" alt=""/>
            }
            {
              gift_tab == 2 &&
              img('images/pop-gift-1.png')
            }

          </div> */}
                    <img
                        src={this.props?.parentProp?.home?.gift_url ? this.props?.parentProp?.home?.gift_url : 'https://cdn.vn.garenanow.com/web/fo4vn/Khoa/Aug2021/cup/qua_cup.png'}
                        alt="" className="pop-gift-cd"/>
                </Modal>
                <Modal show={modal === 'pop-rule'} customClass={'pop-gift pop-no-padd'}
                       closeHandler={() => this.handleClosePopup()}>
                    <div className="pop-progress">
                        {console.log(this.props?.parentProp?.home?.instruction_url)}
                        <img
                            src={this.props?.parentProp?.home?.instruction_url ? this.props?.parentProp?.home?.instruction_url : 'https://cdn.vn.garenanow.com/web/fo4vn//Khoa/Mar2021/Map/cup_hd.png'}
                            alt="" className="pop-hd"/>
                    </div>
                </Modal>

                <Modal show={modal === 'history'} customClass={''} closeHandler={() => this.handleClosePopup()}>
                    <div className="fire-success">
                        <h3 className="md-header">lịch sử nhận quà</h3>
                        <div className="pop-body-history">
                            <Scrollbar className={`top-all-content`} translateContentSizesToHolder={true}>
                                <table className="tbl-history">
                                    <thead>
                                    <tr>
                                        <th>STT</th>
                                        <th>Tên vật phẩm</th>
                                        <th>Trạng thái</th>
                                        <th>Thời gian</th>
                                        <th>Giải đấu</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        history &&
                                        history.map((item, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{item.reward_name}</td>
                                                <td>{item.status}</td>
                                                <td>{item.create_time}</td>
                                                <td>
                                                    {this.mapEventName(item.usage)}
                                                </td>
                                            </tr>
                                        ))
                                    }
                                    </tbody>
                                </table>
                            </Scrollbar>
                        </div>
                    </div>
                </Modal>
            </React.Fragment>
        )
    }
}
