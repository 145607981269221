import React, { useEffect, Suspense } from 'react'
import { Router, Route,Redirect } from 'react-router-dom'
import { Provider, connect } from 'react-redux'
import { Switch } from 'react-router'
import { createBrowserHistory as createHistory } from 'history'
import { updateLocation } from 'store/location'

const history = createHistory();

import MainLayout from 'layouts/PageLayout/PageLayout'
import Loading from 'components/Loading'

const Home = React.lazy(() => import('routes/Home'))

const App = ({ store, currentUser }) => {

  useEffect(() => {
    // history.listen(updateLocation(store))
  }, [])

  return (
    <Provider store={store}>
      <Router
        history={history}
        onUpdate={() => {
          window.scrollTo(0, 0)
        }}>
        <Suspense fallback={<Loading />}>
          <MainLayout>
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
            </Switch>
          </MainLayout>
        </Suspense>
      </Router>
    </Provider>
  )
}
const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
  home: state.home,
  create: state.create,
})
export default connect(mapStateToProps, {})(App)