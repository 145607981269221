import React from 'react'
import PropTypes from 'prop-types'
import {Link, IndexLink} from 'react-router'

import config from 'config/app'

export default class Footer extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <footer>
                <img src="images/footer.png" alt=""/>
            </footer>
        )
    }
}
