import React from 'react'
import swal from "sweetalert2"
export function showErrorWithCode(errCode) {
  let errorMess
  switch (errCode) {
    case 'not_logged_in':
      errorMess = 'Bạn chưa đăng nhập'
      break;
    case 'error_account_not_found':
      errorMess = 'Bạn chưa tạo tài khoản, vui lòng tạo tài khoản và quay lại sau.'
      break;
    case 'server_error':
      errorMess = 'Server bị lỗi, vui lòng thử lại sau.'
      break;
    case 'api_error':
      errorMess = 'Thao tác không thực hiện được, xin vui lòng thử lại sau'
      break;
    case 'permission_denied':
      errorMess = 'Bạn không có quyền thực hiện thao tác này.'
      break;
    case 'event_ended':
      errorMess = 'Sự kiện kết thúc'
      break;
    case 'user_processing':
      errorMess = 'Hệ thống đang xử lý...'
      break;
    case 'uid_not_exist':
      errorMess = 'UID không tồn tại.'
      break;
    case 'invalid_data':
      errorMess = 'Dữ liệu không hợp lệ.'
      break;
    case 'token_error':
      errorMess = 'Vui lòng đăng nhập lại'
      break;
    case 'already_shared':
      errorMess = 'Bạn đã chia sẻ rồi.'
      break;
    case 'already_checked':
      errorMess = 'Bạn đã kiểm tra rồi.'
      break;
    case 'error_invalid_reward':
      errorMess = 'Không thể nhận quà, vui lòng thử lại.'
      break;
    case 'you_already_in_team':
      errorMess = 'Bạn đã thuộc một đội.'
      break;
    case 'not_qualify_team_value':
      errorMess = 'Tạo đội không thành công: Gía trị đội hình không đạt yêu cầu.'
      break;
    case 'same_user':
      errorMess = 'Tạo đội không thành công: Bạn không thể mời chính bạn.'
      break;
    case 'mission_not_taken':
      errorMess = 'Bạn chưa nhận nhiệm vụ.'
      break;
    case 'mission_not_done':
      errorMess = 'Bạn chưa hoàn thành nhiệm vụ.'
      break;
    case 'mission_already_completed':
      errorMess = 'Bạn đã nhận quà rồi.'
      break;
    case 'already_registered':
      errorMess = 'Bạn đã cập nhật thông tin.'
      break;
    case 'not_registered_yet':
      errorMess = 'Bạn cần cập nhật thông tin trước khi tham gia sự kiện.'
      break;
    case 'match_not_exist':
      errorMess = 'Trận đấu không tồn tại.'
      break;
    case 'invalid_result':
      errorMess = 'Kết quả không hợp lệ.'
      break;
    case 'time_is_up':
      errorMess = 'Hết thời gian dự đoán.'
      break;
    case 'already_vote':
      errorMess = 'Đã dự đoán rồi.'
      break;
    case 'score_not_enough':
      errorMess = 'Bạn không còn lượt mở nào.'
      break;
    case 'error_cake_not_enough':
      errorMess = 'Bạn không có bánh để mở.'
      break;
    case 'error_ticket_not_enough':
      errorMess = 'Bạn không có lượt làm bánh.'
      break;
    case 'error_money_not_enough':
      errorMess = 'Bạn không đủ FC/ MC.'
      break;
    case 'error_limit_per_day':
      errorMess = 'Bạn đã đạt giới hạn share trong ngày.'
      break;
    default:
      // errorMess = 'Có lỗi xảy ra. Vui lòng thử lại sau';
      errorMess = errCode
      break;
  }
  return errorMess
}


// var imgCDN = '/'
let imgCDN = 'https://cdn.vn.garenanow.com/web/fo3/fo4/fo4-cup/'
export function smoothScroll(target) {
  if (!target) {
    return
  }
  $('html,body').stop().animate({
    scrollTop: $(target).offset().top - $('header').height()
  }, 1000);
}

export function commingSoonFnc() {
  swal({
    title: 'Thông báo',
    html: '<p class="pop-content">Sự kiện sẽ bắt đầu vào ngày 20.02.2021</p>',
    animation: false,
    confirmButtonText: 'Đóng',
    customClass: 'custom-modal animated zoomIn'
  })
  return
}
export function inWords(num) {
  if (num >= 1e12) return `${Math.floor(num / 1e12)} nghìn tỷ`
  if (num >= 1e9) return `${Math.floor(num / 1e9)} tỷ`
  if (num >= 1e6) return `${Math.floor(num / 1e6)} triệu`
  if (num >= 1e3) return `${Math.floor(num / 1e9)} nghìn`
  else {
    num = 0
  }
  return num
}
export function formatNumber(num) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
}
export function img(src, imgClass) {
  return <img className={imgClass ? imgClass : ''} src={imgCDN + src} alt="" />
}
export const isStartEvent = true
export const inGame = new URLSearchParams(location.search).get('ingame')
export function ReplaceNewString(str, key, newStr) {
  if(!str || str == ''){
    return
  }
  return str.split(key)[0] + newStr
}
export function getPercent(range, current, min, max) {
  let cIndex = 0
  if (current == 0) {
    return min;
  }

  if (current >= range[range.length - 1]) {
    return max;
  }

  for (var i = 0; i < range.length; i++) {
    if (range[i] >= current) {
      cIndex = i
      break;
    }
  }

  let subPercent = (max - min) * 1.0 / (range.length - 1)
  console.log(range[cIndex - 1])
  let result = min + ((current - range[cIndex - 1]) * 1.0 / (range[cIndex] - range[cIndex - 1]) + (cIndex - 1)) * subPercent
  console.log(result)
  return result
}
export async function sleep(time) {
  return new Promise((rs, rj) => {
    setTimeout(() => {
      rs();
    }, time);
  });
}
export const mockData = {"status":"successful","payload":{"current_datetime":"2021-03-31 01:21:44","user":{"map_prize":[{"image":"https://cdn.vn.garenanow.com/fo3vn/project/playerPack/500204116.png","product_name":"Gói VTR, MG, LH 94+","id":2},{"image":"https://cdn.vn.garenanow.com/fo3vn/project/playerPack/500204124.png","product_name":"Gói VTR, MG, BoE, LH 95+","id":3},{"image":"https://cdn.vn.garenanow.com/fo3vn/project/playerPack/500300111.png","product_name":"20,000,000 BP","id":4},{"image":"https://cdn.vn.garenanow.com/fo3vn/project/playerPack/500204290.png","product_name":"Gói MC, VTR 94+","id":5},{"image":"https://cdn.vn.garenanow.com/fo3vn/project/playerPack/500204353.png","product_name":"Gói MC, VTR, MG 95+","id":6}],"map_id":1,"account_id":"90c5b4f6ed479a10c278bc51","need_register":0,"user_type":"new","map_info":{"map":[0,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1],"pos_x":0,"pos_y":0,"items":[]},"stamina":0,"nickname":"BN1883","id":2,"uid":"200126"},"event_started":1}}
