import {
	CURRENT_USER_REQUEST_ERROR,
	CURRENT_USER_REQUEST_SUCCESS,
	CURRENT_USER_FETCHING,
	IS_GET_API,
	OPEN_CARD_SUCCESS,
	UPDATE_INFO_SUCCESS,
	CHECK_PROGRESS_SUCCESS,
	SHARE_FB_SUCCESS,
	GET_HISTORY_SUCCESS,
	CLAIM_MEDAL_SUCCESS,
	CLAIM_MISSION_SUCCESS,
	CHECK_MISSION_SUCCESS,
	VOTE_TEAM_SUCCESS,
	CHANGE_MAP_SUCCESS,
} from './actions';

const convertDateForIos = (date) => {
	var arr = date.split(/[- :]/);
	date = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);
	return date;
};

const initialState = {
	loading: false,
	login: false,
	user: null,
	mission_daily: null,
	player_pieces: null,
	server_prizes: null,
	spin_prizes: null,
	total_count: null,
	current_datetime: null,
	total_win: 0,
	online: 0,
	history: null,
	matches: null,
	special_date: null,
	delta: 0,
};

export default function eventReducer(state = initialState, action) {
	switch (action.type) {
		case CURRENT_USER_REQUEST_SUCCESS:
			return {
				...state,
				loading: false,
				login: true,
				user: action.payload.user,
				event_started: action.payload.event_started,
				server_prizes: action.payload.server_prizes,
				spin_prizes: action.payload.spin_prizes,
				current_datetime: action.payload.current_datetime,
				gift_url: action.payload.gift_url,
				title_url: action.payload.title_url,
				instruction_url: action.payload.instruction_url,
				bg_url: action.payload.bg_url,
				special_date: action.payload.special_date,
				delta:
					new Date(
						convertDateForIos(action.payload.current_datetime)
					).getTime() - new Date().getTime(),
			};
			break;
		case CURRENT_USER_REQUEST_ERROR:
			return {
				...state,
				loading: false,
				event_started: action.payload.event_started,
				server_prizes: action.payload.server_prizes,
				spin_prizes: action.payload.spin_prizes,
				current_datetime: action.payload.current_datetime,
				gift_url: action.payload.gift_url,
				title_url: action.payload.title_url,
				bg_url: action.payload.bg_url,
				special_date: action.payload.special_date,
				delta:
					new Date(
						convertDateForIos(action.payload.current_datetime)
					).getTime() - new Date().getTime(),
			};
			break;
		case CURRENT_USER_FETCHING:
			return {
				...state,
				loading: true,
			};
			break;
		case IS_GET_API:
			return {
				...state,
				loading: true,
			};
			break;
		case CHECK_PROGRESS_SUCCESS:
			return {
				...state,
				loading: false,
				total_win: action.payload.total_play,
				online: action.payload.online,
				user: {
					...state.user,
					...action.payload.user,
				},
			};
			break;
		case CHANGE_MAP_SUCCESS:
			return {
				...state,
				loading: false,
				user: {
					...state.user,
					...action.payload.user,
				},
			};
			break;

		case UPDATE_INFO_SUCCESS:
			return {
				...state,
				loading: false,
				user: {
					...state.user,
					...action.payload.user,
				},
			};
		case CLAIM_MISSION_SUCCESS:
			return {
				...state,
				loading: false,
				user: {
					...state.user,
					...action.payload.user,
				},
			};
		case CHECK_MISSION_SUCCESS:
			return {
				...state,
				loading: false,
				user: {
					...state.user,
					...action.payload.user,
				},
			};
		case CLAIM_MEDAL_SUCCESS:
			return {
				...state,
				loading: false,
				user: {
					...state.user,
					...action.payload.user,
				},
			};
		case GET_HISTORY_SUCCESS:
			return {
				...state,
				loading: false,
				history: action.payload,
			};
			break;
		case OPEN_CARD_SUCCESS:
			return {
				...state,
				loading: false,
				user: {
					...state.user,
					...action.payload.user,
				},
			};
			break;
		case VOTE_TEAM_SUCCESS:
			return {
				...state,
				loading: false,
				user: {
					...state.user,
					...action.payload.user,
				},
			};
			break;
		case SHARE_FB_SUCCESS:
			return {
				...state,
				loading: false,
				total_count: action.payload.total_count,
				user: {
					...state.user,
					...action.payload.user,
				},
			};
			break;
		default:
			return state;
	}
}
